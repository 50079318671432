// import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
// import colors from 'vuetify/lib/util/colors'
// import nodejs from '../assets/icons/nodejs.vue'


Vue.use(Vuetify)


export default new Vuetify({
  
    theme: {
       dark: true ,
          themes: {
            light: {
              primary: '#2A0052',
              secondary: '#376995',
              accent: '#AEC9D6',
              warning:'#FFC145',
              error: '#FF4444',
              // darkTheme:'#21003F'
              // background: colors.yellow.lighten5
            },
            dark: {
              primary: "#460087",
              secondary: '#8FB5D6',
              accent: '#AEC9D6',
              warning:'#FFC145',
              error: '#FF4444',
              darkTheme:'#FFC145'

              // background: colors.grey.darken3
            }
          }
        }
    
  })