import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import VueRouter from 'vue-router'
import { routes } from './router.js'
import VueTilt from 'vue-tilt.js'
import VueMeta from 'vue-meta'
import VueGtag from "vue-gtag";
// import VueTidio from 'vue-tidio';
import '../src/sass/app.scss'




// Vue.use(VueTidio, { appKey: '3qu8z3gx1iyzd5nyzhwqsmh23qjdqz2g'})
// Vue.prototype.tidioChatApi.display(true);


Vue.use(VueGtag, {
  config: { id: "G-EFZEPJDZVP" },
  appName: 'The Code Effect',
  pageTrackerScreenviewEnabled: true
}, router);

Vue.use(VueTilt)
Vue.use(VueRouter);
Vue.use(VueMeta)

export const router = new VueRouter({

  
  scrollBehavior (to, from, savedPosition) {
    
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
  
  ,
  mode:'history',
  routes,
  })
  
  router.afterEach(() => {
    window.tidioChatApi && window.tidioChatApi.display(false);
  });
 


new Vue({
  vuetify,
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),

}).$mount('#app')
