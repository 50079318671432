<template>

  <v-app  :style="{ background: $vuetify.theme.themes[theme].background}">
  <div class="section1">
 <div id="app">

    <transition  name="fade">

      <router-view></router-view>
    </transition>
  </div>

  </div>
  </v-app>
 
</template>


<script>
import '@mdi/font/css/materialdesignicons.css'
// import {window} from 'vue'

export default {
  name: 'App',
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Software Solutions in Sudan',
      // all titles will be injected into this template
      titleTemplate: 'The Code Effect | %s',
      content: 'Achieve your Maximum Potential with Tailored Software Solutions. The Code Effect is a Tech company in Sudan. We bring great ideas to life. From simple Website Design to complex, ECommerce Mobile Apps.'
    },
  computed:{
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light' 
    }
  },
  created() {
      window.tidioChatApi.display(true);
  },
}
</script>
