// import {router} from './main'

const Home = () => import('../src/pages/Home');
const Protfolio = () => import('../src/pages/Portfolio')
const Services  = () => import( '../src/pages/Services')
const Contact = () => import( '../src/pages/Contact')
const Navigation = () => import('../src/pages/Navigation')
const About = () => import('../src/pages/About')
const Web  = () => import('../src/pages/Web')
const Mobile = () => import('../src/pages/Mobile')
const System = () => import('../src/pages/System')
const DigitalMarketing = () => import( '../src/pages/DigitalMarketing')
const Menasave = () => import('../src/pages/Menasave.vue')
const TheUrbanEpisode = () => import('../src/pages/TheUrbanEpisode.vue')
const Karmakol = () => import('../src/pages/Karmakol.vue')
const MaryudFM = () => import('../src/pages/MaryudFm.vue') 
const Moftahk = () => import('../src/pages/Moftahk.vue')

export const routes =  [
    { path: '*', component: Home, meta: {showChat: true} },
    { path: '/portfolio', component: Protfolio },
    { path: '/ourservices', component: Services },
    { path: '/contact', component: Contact },
    { path: '/nav', component: Navigation },
    { path: '/aboutus', component: About},
    { path: '/mobile-development', component: Mobile },
    { path: '/web-development', component: Web },
    { path: '/digital-marketing', component: DigitalMarketing },
    { path: '/system-development', component: System },
    { path: '/portfolio/menasave', component: Menasave },
    { path: '/portfolio/the-urban-episode', component: TheUrbanEpisode },
    { path: '/portfolio/karmakol', component: Karmakol},
    { path: '/portfolio/maryud-fm', component: MaryudFM},
    { path: '/portfolio/moftahk', component: Moftahk }
        
    
]
 